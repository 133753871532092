import { PageTypeEnum, ReportType } from "utils/reportDefinitions";

export const StringResources = {
    modal: {
        close: "modal.close",
        confirm: "modal.confirm",
        delete: "modal.delete",
        cancel: "modal.cancel",
        set: "modal.set",
        details: "modal.details",
        submit: "modal.submit",
        feedback: {
            title: "modal.feedback.title",
            label: "modal.feedback.label",
            placeholder: "modal.feedback.placeholder",
            success: "modal.feedback.success",
            error: "modal.feedback.error",
            message: "modal.feedback.message",
        },
        column: "modal.column",
        filter: "modal.filter",
        deleteModal: {
            title: "modal.deleteModal.title",
            message: "modal.deleteModal.message",
        },
        refundTransaction: {
            title: "modal.refundTransaction.title",
            message: "modal.refundTransaction.message",
            refund: "modal.refundTransaction.refund",
            fullRefund: "modal.refundTransaction.fullRefund",
            partialRefund: "modal.refundTransaction.partialRefund",
            formRefundAmount: "modal.refundTransaction.formRefundAmount",
            formRefundAmountRequired: "modal.refundTransaction.formRefundAmountRequired",
            formRefundAmountMin: "modal.refundTransaction.formRefundAmountMin",
            formRefundAmountMax: "modal.refundTransaction.formRefundAmountMax",
            maxAmountForRefund: "modal.refundTransaction.maxAmountForRefund",
            amountForRefund: "modal.refundTransaction.amountForRefund",
            success: "modal.refundTransaction.success",
            error: "modal.refundTransaction.error",
            confirmRefund: "modal.refundTransaction.confirmRefund",
            confirmRefundMessage: "modal.refundTransaction.confirmRefundMessage",
        },
        reversalAuthorization: {
            reversal: "modal.reversalAuthorization.reversal",
            confirmReversalMessage: "modal.reversalAuthorization.confirmReversalMessage",
            success: "modal.reversalAuthorization.success",
            error: "modal.reversalAuthorization.error",
        },
    },
    errorBoundary: {
        title: "errorBoundary.title",
        subtitle: "errorBoundary.subtitle",
        btnText: "errorBoundary.btnText",
    },
    mainNavigation: {
        dashboard: "mainNavigation.dashboard",
        payments: "mainNavigation.payments",
        agreements: "mainNavigation.agreements",
        settlements: "mainNavigation.settlements",
        transactions: "mainNavigation.transactions",
        users: "mainNavigation.users",
        notifications: "mainNavigation.notifications",
        settings: "mainNavigation.settings",
        logout: "mainNavigation.logout",
        merchantAgreements: "mainNavigation.merchantAgreements",
        merchantSettlements: "mainNavigation.merchantSettlements",
    },
    dashboard: {
        totalSales: "dashboard.totalSales",
        totalSalesTooltip: "dashboard.totalSalesTooltip",
        unpaidCommissionAmountToolTip: "dashboard.unpaidCommissionAmountToolTip",
        numberOfSales: "dashboard.numberOfSales",
        averageNumberOfSales: "dashboard.averageNumberOfSales",
        grossSales: "dashboard.grossSales",
        comission: "dashboard.comission",
        averageComission: "dashboard.averageComission",
        averageGrossSales: "dashboard.averageGrossSales",
        day: "dashboard.day",
        month: "dashboard.month",
        noData: "dashboard.noData",
        exportGraph: "dashboard.exportGraph",
        january: "dashboard.january",
        february: "dashboard.february",
        march: "dashboard.march",
        april: "dashboard.april",
        may: "dashboard.may",
        june: "dashboard.june",
        july: "dashboard.july",
        august: "dashboard.august",
        september: "dashboard.september",
        october: "dashboard.october",
        november: "dashboard.november",
        december: "dashboard.december",
        unpaidCommissionAmount: "dashboard.unpaidCommissionAmount",
        partnerContract: "dashboard.partnerContract",
        allPartnerContracts: "dashboard.allPartnerContracts",
        salesTileTooltip: "dashboard.salesTileTooltip",
        salesTileTitle: "dashboard.salesTileTitle",
        turnOverTileTooltip: "dashboard.turnOverTileTooltip",
        turnOverTileTitle: "dashboard.turnOverTileTitle",
    },
    userMenu: {
        back: "userMenu.back",
    },
    filter: {
        totalSubtotal: "filter.totalSubtotal",
        clear: "filter.clear",
        search: "filter.search",
        dropdownSearchPlaceholder: "filter.dropdownSearchPlaceholder",
        currency: "filter.currency",
        buttonText: "filter.buttonText",
        allMerchants: "filter.allMerchants",
    },
    columnChooser: {
        column: "columnChooser.column",
        unsavedChanges: "columnChooser.unsavedChanges",
        save: "columnChooser.save",
        cancel: "columnChooser.cancel",
        selectAll: "columnChooser.selectAll",
        defaultView: "columnChooser.defaultView",
    },
    reportDetails: {
        boolean: {
            yesLabel: "reportDetails.boolean.yesLabel",
            noLabel: "reportDetails.boolean.noLabel",
        },
    },
    table: {
        cell: {
            boolean: {
                yesLabel: "table.cell.boolean.yesLabel",
                noLabel: "table.cell.boolean.noLabel",
            },
        },
        fallbackMessage: {
            default: "table.fallbackMessage.default",
            [PageTypeEnum.Agreements]: "table.fallbackMessage.agreements",
            [PageTypeEnum.MerchantAgreements]: "table.fallbackMessage.merchantAgreements",
            [PageTypeEnum.Payments]: "table.fallbackMessage.payments",
            [PageTypeEnum.Settlements]: "table.fallbackMessage.settlements",
            [PageTypeEnum.Transactions]: "table.fallbackMessage.transactions",
            [PageTypeEnum.Employees]: "table.fallbackMessage.users",
            [PageTypeEnum.None]: "table.fallbackMessage.default",
            [PageTypeEnum.Dashboard]: "table.fallbackMessage.default",
            [PageTypeEnum.NotFound]: "table.fallbackMessage.default",
            [PageTypeEnum.MerchantSettlements]: "table.fallBackMessage.merchantSettlements",
            [PageTypeEnum.PartnerAgreementDetails]: "table.fallbackMessage.agreementDetails",
            filtersRequired: "table.fallbackMessage.filtersRequired",
        },
        fallbackMessageWithFilter: "table.fallbackMessageWithFilter",
        itemsPerPage: "table.itemsPerPage",
    },
    report: {
        export: "report.export",
        print: "report.print",
    },
    toolTip: {
        [PageTypeEnum.Payments]: {
            searchBy: "toolTip.payments.searchBy",
            agreementNumber: "toolTip.payments.agreementNumber",
            dbaNames: "toolTip.payments.dbaNames",
            settlements: "toolTip.payments.settlements",
            paymentPeriod: "toolTip.payments.paymentPeriod",
        },
        [PageTypeEnum.Settlements]: {
            searchBy: "toolTip.settlements.searchBy",
            settlementNumber: "toolTip.settlements.settlementNumber",
            agreementId: "toolTip.settlements.agreementId",
            dbaNames: "toolTip.settlements.dbaNames",
        },
        [PageTypeEnum.MerchantSettlements]: {
            searchBy: "toolTip.merchantSettlements.searchBy",
            settlementNumber: "toolTip.merchantSettlements.settlementNumber",
            agreementNumber: "toolTip.merchantSettlements.agreementNumber",
            dbaNames: "toolTip.merchantSettlements.dbaNames",
        },
        [PageTypeEnum.Transactions]: {
            searchBy: "toolTip.transactions.searchBy",
            transactionId: "toolTip.transactions.transactionId",
            authNumber: "toolTip.transactions.authNumber",
            arn: "toolTip.transactions.arn",
            terminalId: "toolTip.transactions.terminalId",
            physicalTerminalId: "toolTip.transactions.physicalTerminalId",
            batchNumber: "toolTip.transactions.batchNumber",
            settlementNumber: "toolTip.transactions.settlementNumber",
            agreementId: "toolTip.transactions.agreementId",
            partnerId: "toolTip.transactions.partnerId",
            externalTransactionId: "toolTip.transactions.externalTransactionId",
        },
    },
    pages: {
        [PageTypeEnum.Agreements]: {
            title: "pages.agreements.title",
            viewSettlements: "pages.agreements.viewSettlements",
            merchantName: "pages.agreements.merchantName",
            agreementNumber: "pages.agreements.agreementNumber",
            partnerAgreementNumber: "pages.agreements.partnerAgreementNumber",
            partnerAgreementDescription: "pages.agreements.partnerAgreementDescription",
            createdTime: "pages.agreements.createdTime",
            cardPresent: "merchant.agreements.cardPresent",
            cardNotPresent: "merchant.agreements.cardNotPresent",
            type: "pages.agreements.type",
            status: "pages.agreements.status",
            currency: "pages.agreements.currency",
            currencyCode: "pages.agreements.currencyCode",
            agreementBalance: "pages.agreements.agreementBalance",
            totalBalance: "pages.agreements.totalBalance",
            merchantNumber: "pages.agreements.merchantNumber",
            merchantRegistrationNumber: "pages.agreements.merchantRegistrationNumber",
            isCardPresent: "pages.agreements.isCardPresent",
            settlements: "pages.agreements.settlements",
            totalFixedAmount: "pages.agreements.totalFixedAmount",
            totalPercentage: "pages.agreements.totalPercentage",
            totalCommission: "pages.agreements.totalCommission",
            unpaidCommission: "pages.agreements.unpaidCommission",
            description: "pages.agreements.description",
            solution: "pages.agreements.solution",
        },
        [PageTypeEnum.MerchantAgreements]: {
            title: "pages.merchantAgreements.title",
            viewSettlements: "pages.merchantAgreements.viewSettlements",
            merchantName: "pages.merchantAgreements.merchantName",
            agreementNumber: "pages.merchantAgreements.agreementNumber",
            createdTime: "pages.merchantAgreements.createdTime",
            cardPresent: "merchant.merchantAgreements.cardPresent",
            cardNotPresent: "merchant.merchantAgreements.cardNotPresent",
            type: "pages.merchantAgreements.type",
            status: "pages.merchantAgreements.status",
            currency: "pages.merchantAgreements.currency",
            currencyCode: "pages.merchantAgreements.currencyCode",
            agreementBalance: "pages.merchantAgreements.agreementBalance",
            totalBalance: "pages.merchantAgreements.totalBalance",
            merchantNumber: "pages.merchantAgreements.merchantNumber",
            merchantRegistrationNumber: "pages.merchantAgreements.merchantRegistrationNumber",
            isCardPresent: "pages.merchantAgreements.isCardPresent",
            settlements: "pages.merchantAgreements.settlements",
        },
        [PageTypeEnum.Dashboard]: {
            title: "pages.dashboard.title",
            date: "pages.dashboard.date",
            setSalesTarget: "pages.dashboard.setSalesTarget",
            targetAmount: "pages.dashboard.targetAmount",
        },
        [PageTypeEnum.Payments]: {
            title: "pages.payments.title",
            merchantName: "pages.payments.merchantName",
            breakdown: "pages.payments.breakdown",
            breakdownNoRecords: "pages.payments.breakdownNoRecords",
            settlement: "pages.payments.settlement",
            netPurchase: "pages.payments.netPurchase",
            payout: "pages.payments.payout",
            search: "pages.payments.search",
            dateFrom: "pages.payments.dateFrom",
            dateTo: "pages.payments.dateTo",
            amountFrom: "pages.payments.amountFrom",
            amountTo: "pages.payments.amountTo",
            agreementNumber: "pages.payments.agreementNumber",
            currency: "pages.payments.currency",
            swift: "pages.payments.swift",
            iban: "pages.payments.iban",
            payoutAmount: "pages.payments.payoutAmount",
            feeAmounts: "pages.payments.feeAmounts",
            grossAmounts: "pages.payments.grossAmounts",
            paidDate: "pages.payments.paidDate",
            scheduled: "pages.payments.scheduled",
            paymentPeriod: "pages.payments.paymentPeriod",
            partnerId: "pages.payments.partnerId",
            partnerName: "pages.payments.partnerName",
            dbaNames: "pages.payments.dbaNames",
            ssn: "pages.payments.ssn",
            settlementType: "pages.payments.settlementType",
            settlements: "pages.payments.settlements",
            printReportError: "pages.payments.printReportError",
            agreement: "pages.payments.agreement",
            merchant: "pages.payments.merchant",
        },
        [PageTypeEnum.Settlements]: {
            title: "pages.settlements.title",
            breakdown: "pages.settlements.breakdown",
            search: "pages.settlements.search",
            paidDateFrom: "pages.settlements.paidDateFrom",
            paidDateTo: "pages.settlements.paidDateTo",
            amountFrom: "pages.settlements.amountFrom",
            amountTo: "pages.settlements.amountTo",
            allAgreements: "pages.settlements.allAgreements",
            currency: "pages.settlements.currency",
            grossPurchase: "pages.settlements.grossPurchase",
            grossSettlement: "pages.settlements.grossSettlement",
            feeAmount: "pages.settlements.feeAmount",
            totalFees: "pages.settlements.totalFees",
            netSettlement: "pages.settlements.netSettlement",
            commissionAmount: "pages.settlements.commissionAmount",
            percentageAmount: "pages.settlements.percentageAmount",
            fixedAmount: "pages.settlements.fixedAmount",
            batches: "pages.settlements.batches",
            batchNoRecords: "pages.settlements.batchNoRecords",
            viewBatches: "pages.settlements.viewBatches",
            viewTransactions: "pages.settlements.viewTransactions",
            dbaName: "pages.settlements.dbaName",
            gross: "pages.settlements.gross",
            netPurchase: "pages.settlements.netPurchase",
            date: "pages.settlements.date",
            batch: "pages.settlements.batch",
            batchTotal: "pages.settlements.batchTotal",
            batchNumber: "pages.settlements.batchNumber",
            status: "pages.settlements.status",
            settlementFee: "pages.settlements.settlementFee",
            paid: "pages.settlements.paid",
            agreementId: "pages.settlements.agreementId",
            settlementType: "pages.settlements.settlementType",
            refundAmount: "pages.settlements.refundAmount",
            createdDate: "pages.settlements.createdDate",
            scheduledDate: "pages.settlements.scheduledDate",
            settlementNumber: "pages.settlements.settlementNumber",
            batchesCount: "pages.settlements.batchesCount",
            transactionsCount: "pages.settlements.transactionsCount",
            cumulativeFees: "pages.settlements.cumulativeFees",
            payout: "pages.settlements.payout",
            partnerId: "pages.settlements.partnerId",
            partnerName: "pages.settlements.partnerName",
            deduction: "pages.settlements.deduction",
            representment: "pages.settlements.representment",
            chargeback: "pages.settlements.chargeback",
            rollingReserve: "pages.settlements.rollingReserve",
            rollingRelease: "pages.settlements.rollingRelease",
            chargebackFees: "pages.settlements.chargebackFees",
            swiftFees: "pages.settlements.swiftFees",
            transactionFee: "pages.settlements.transactionFee",
            joiningFee: "pages.settlements.joiningFee",
            rejected: "pages.settlements.rejected",
            addedSum: "pages.settlements.addedSum",
            deductedSum: "pages.settlements.deductedSum",
            reversals: "pages.settlements.reversals",
            preArbitration: "pages.settlements.preArbitration",
            posRentalFee: "pages.settlements.posRentalFee",
            exceptionCorrection: "pages.settlements.exceptionCorrection",
            dccCommission: "pages.settlements.dccCommission",
            dbaNames: "pages.settlements.dbaNames",
            ssn: "pages.settlements.ssn",
            claim: "pages.settlements.claim",
            claimDueDate: "pages.settlements.claimDueDate",
            aukakronurFee: "pages.settlements.aukakronurFee",
            otherFees: "pages.settlements.otherFees",
            gomobileFee: "pages.settlements.gomobileFee",
            gomobileInitialFee: "pages.settlements.gomobileInitialFee",
            preAuthorizationFee: "pages.settlements.preAuthorizationFee",
            monthlyFee: "pages.settlements.monthlyFee",
            authorizationFee: "pages.settlements.authorizationFee",
            minimumMonthlyServiceFee: "pages.settlements.minimumMonthlyServiceFee",
            cardNotPresentFee: "pages.settlements.cardNotPresentFee",
            pciFee: "pages.settlements.pciFee",
            mobileAirTimeFee: "pages.settlements.mobileAirTimeFee",
            ecomGatewayMonthlyFee: "pages.settlements.ecomGatewayMonthlyFee",
            ecomGatewayTransactionFee: "pages.settlements.ecomGatewayTransactionFee",
            merchantName: "pages.settlements.merchantName",
            print: "pages.settlements.print",
            printReportError: "pages.settlements.printReportError",
        },
        [PageTypeEnum.MerchantSettlements]: {
            ssn: "pages.merchantSettlements.ssn",
            createdDate: "pages.merchantSettlements.createdDate",
            scheduledDate: "pages.merchantSettlements.scheduledDate",
            grossSettlement: "pages.merchantSettlements.grossSettlement",
            netSettlement: "pages.merchantSettlements.netSettlement",
            currency: "pages.merchantSettlements.currency",
            dbaNames: "pages.merchantSettlements.dbaNames",
            agreementId: "pages.merchantSettlements.agreementId",
            status: "pages.merchantSettlements.status",
            paid: "pages.merchantSettlements.paid",
            settlementNumber: "pages.merchantSettlements.settlementNumber",
            settlementType: "pages.merchantSettlements.settlementType",
            transactionsCount: "pages.merchantSettlements.transactionsCount",
            batchesCount: "pages.merchantSettlements.batchesCount",
            netPurchase: "pages.merchantSettlements.netPurchase",
            feeAmount: "pages.merchantSettlements.feeAmount",
            refundAmount: "pages.merchantSettlements.refundAmount",
            representment: "pages.merchantSettlements.representment",
            chargeback: "pages.merchantSettlements.chargeback",
            rejected: "pages.merchantSettlements.rejected",
            reversals: "pages.merchantSettlements.reversals",
            search: "pages.merchantSettlements.search",
            allAgreements: "pages.merchantSettlements.allAgreements",
            amountFrom: "pages.merchantSettlements.amountFrom",
            amountTo: "pages.merchantSettlements.amountTo",
            paidDateFrom: "pages.merchantSettlements.paidDateFrom",
            paidDateTo: "pages.merchantSettlements.paidDateTo",
            createdDateFrom: "pages.merchantSettlements.createdDateFrom",
            createdDateTo: "pages.merchantSettlements.createdDateTo",
            title: "pages.merchantSettlements.title",
            grossPurchase: "pages.merchantSettlements.grossPurchase",
            breakdown: "pages.merchantSettlements.breakdown",
            partnerCommission: "pages.merchantSettlements.partnerCommission",
        },
        [PageTypeEnum.Transactions]: {
            title: "pages.transactions.title",
            commissionAmount: "pages.transactions.commissionAmount",
            fixedAmount: "pages.transactions.fixedAmount",
            percentageAmount: "pages.transactions.percentageAmount",
            cardNumber: "pages.transactions.cardNumber",
            settlementNumber: "pages.transactions.settlementNumber",
            cardType: "pages.transactions.cardType",
            cardIssuer: "pages.transactions.cardIssuer",
            settlementType: "pages.transactions.settlementType",
            transactionId: "pages.transactions.transactionId",
            externalTransactionId: "pages.transactions.externalTransactionId",
            authorizationDate: "pages.transactions.authorizationDate",
            dbaName: "pages.transactions.dbaName",
            referenceData: "pages.transactions.referenceData",
            purchaseDate: "pages.transactions.purchaseDate",
            originalAmount: "pages.transactions.originalAmount",
            gross: "pages.transactions.gross",
            netAmount: "pages.transactions.netAmount",
            currency: "pages.transactions.currency",
            secure3D: "pages.transactions.secure3D",
            batch: "pages.transactions.batch",
            registrationNumber: "pages.transactions.registrationNumber",
            reasonCode: "pages.transactions.reasonCode",
            contractId: "pages.transactions.contractId",
            merchantBucket: "pages.transactions.merchantBucket",
            physicalTermianlId: "pages.transactions.physicalTermianlId",
            lifeCycleId: "pages.transactions.lifeCycleId",
            paid: "pages.transactions.paid",
            terminalId: "pages.transactions.terminalId",
            arn: "pages.transactions.arn",
            fees: "pages.transactions.fees",
            originalCurrency: "pages.transactions.originalCurrency",
            registrationDate: "pages.transactions.registrationDate",
            transactionType: "pages.transactions.transactionType",
            merchantFee: "pages.transactions.merchantFee",
            authorizationCode: "pages.transactions.authorizationCode",
            cardEntryModeType: "pages.transactions.cardEntryModeType",
            authenticationMethodType: "pages.transactions.authenticationMethodType",
            authorizationMethodType: "pages.transactions.authorizationMethodType",
            isReversal: "pages.transactions.isReversal",
            search: "pages.transactions.search",
            agreementNumber: "pages.transactions.agreementNumber",
            amountFrom: "pages.transactions.amountFrom",
            amountTo: "pages.transactions.amountTo",
            purchaseDateFrom: "pages.transactions.purchaseDateFrom",
            purchaseDateTo: "pages.transactions.purchaseDateTo",
            registrationDateFrom: "pages.transactions.registrationDateFrom",
            registrationDateTo: "pages.transactions.registrationDateTo",
            paidDateFrom: "pages.transactions.paidDateFrom",
            paidDateTo: "pages.transactions.paidDateTo",
            merchant: "pages.transactions.merchant",
            partnerContractId: "pages.transactions.partnerContractId",
            partnerSettlementPaymentId: "pages.transactions.partnerSettlementPaymentId",
        },
        [PageTypeEnum.Employees]: {
            title: "pages.employees.title",
            permissions: "pages.employees.permissions",
            apiKey: "pages.employees.apiKey",
            table: {
                usernameHeaderLabel: "pages.employees.table.usernameHeaderLabel",
                statusHeaderLabel: "pages.employees.table.statusHeaderLabel",
                statusActiveLabel: "pages.employees.table.statusActiveLabel",
                statusInactiveLabel: "pages.employees.table.statusInactiveLabel",
                parnterUserRoleHeaderLabel: "pages.employees.table.parnterUserRoleHeaderLabel",
            },
            create: {
                buttonText: "pages.employees.create.buttonText",
                title: "pages.employees.create.title",
                permissions: "pages.employees.create.permissions",
                permissionsPage: "pages.employees.create.permissionsPage",
                confirm: "pages.employees.create.confirm",
                close: "pages.employees.create.close",
                formEmailInputLabel: "pages.employees.create.formEmailInputLabel",
                formContractInputLabel: "pages.employees.create.formContractInputLabel",
                formButtonCaption: "pages.employees.create.formButtonCaption",
                formEmailValidationMsg: "pages.employees.create.formEmailValidationMsg",
                formUsernameAlreadyHasAccessValidationMsg:
                    "pages.employees.create.formUsernameAlreadyHasAccessValidationMsg",
                successMsg: "pages.employees.create.successMsg",
            },
            edit: {
                title: "pages.employees.edit.title",
                permissions: "pages.employees.edit.permissions",
                permissionsPage: "pages.employees.edit.permissionsPage",
                confirm: "pages.employees.edit.confirm",
                deactivate: "pages.employees.edit.deactivate",
                close: "pages.employees.edit.close",
                formContractInputLabel: "pages.employees.edit.formContractInputLabel",
                successMsg: "pages.employees.edit.successMsg",
                errorMsg: "pages.employees.edit.errorMsg",
                errorApiKeyMsg: "pages.employees.edit.errorApiKeyMsg",
                successDeactivateMsg: "pages.employees.edit.successDeactivateMsg",
            },
            details: {
                employeeId: "pages.employees.details.employeeId",
                username: "pages.employees.details.username",
                status: "pages.employees.details.status",
                role: "pages.employees.details.role",
            },
        },
        [PageTypeEnum.NotFound]: {
            title: "pages.notFound.title",
            description: "pages.notFound.description",
            link: "pages.notFound.link",
        },
        [PageTypeEnum.None]: {
            title: "pages.none.title",
        },
        settings: {
            title: "pages.settings.title",
            generalTab: "pages.settings.generalTab",
            emailsAndNotificationsTab: "pages.settings.emailsAndNotifications",
            changePasswordTab: "pages.settings.changePasswordTab",
            accountAndSecurity: {
                title: "pages.settings.accountAndSecurity.title",
                details: "pages.settings.accountAndSecurity.details",
                changePasswordTitle: "pages.settings.accountAndSecurity.changePasswordTitle",
                username: "pages.settings.accountAndSecurity.username",
            },
            general: {
                defaultLanguage: "pages.settings.general.defaultLanguage",
            },
            changePassword: {
                oldPassword: "pages.settings.changePassword.oldPassword",
                newPassword: "pages.settings.changePassword.newPassword",
                repeatNewPassword: "pages.settings.changePassword.repeatNewPassword",
                saveNewPassword: "pages.settings.changePassword.saveNewPassword",
                confirmNewPassword: "pages.settings.changePassword.confirmNewPassword",
                currentPassword: "pages.settings.changePassword.currentPassword",
            },
            emailsAndNotifications: {
                statementsTitle: "pages.settings.emailsAndNotifications.statementsTitle",
                statementsRadioTitle: "pages.settings.emailsAndNotifications.statementsRadioTitle",
                statementsRadioPdfEmail: "pages.settings.emailsAndNotifications.statementsRadioPdfEmail",
                statementsRadioNotificationEmail:
                    "pages.settings.emailsAndNotifications.statementsRadioNotificationEmail",
                statementsRadioNoEmail: "pages.settings.emailsAndNotifications.statementsRadioNoEmail",
                radioChange: {
                    statementsRadioError: "pages.settings.emailsAndNotifications.radioChange.error",
                    statementsRadioSuccess: "pages.settings.emailsAndNotifications.radioChange.success",
                },
            },
        },
        [PageTypeEnum.PartnerAgreementDetails]: {
            title: "pages.agreementDetails.title",
            details: "pages.agreementDetails.details",
            unpaidCommission: "pages.agreementDetails.unpaidCommission",
            description: "pages.agreementDetails.description",
            partnerContractStatus: "pages.agreementDetails.partnerContractStatus",
            partnerContractNumber: "pages.agreementDetails.partnerContractNumber",
            settlementCurrency: "pages.agreementDetails.settlementCurrency",
            detailsAndUnpaidCommission: "pages.agreementDetails.detailsAndUnpaidCommission",
        },
    },
    export: {
        confirm: "export.confirm",
        title: "export.title",
        close: "export.close",
        exportType: "export.exportType",
        exportTypeCSV: "export.exportTypeCSV",
        exportTypeXLSX: "export.exportTypeXLSX",
        exportAllColumns: "export.exportAllColumns",
        exportVisibleColumns: "export.exportVisibleColumns",
        exportColumn: "export.exportColumn",
        exportSuccess: "export.exportSuccess",
        exportError: "export.exportError",
        exportInfo: "export.exportInfo",
        exportToManyRows: "export.exportToManyRows",
    },
    input: {
        passwordShowCaption: "input.passwordShowCaption",
        passwordHideCaption: "input.passwordHideCaption",
        passwordStrengthBarLabel: "input.passwordStrengthBarLabel",
        passwordStrengthBad: "input.passwordStrengthBad",
        passwordStrengthWeak: "input.passwordStrengthWeak",
        passwordStrengthPassable: "input.passwordStrengthPassable",
        passwordStrengthGood: "input.passwordStrengthGood",
        passwordStrengthVeryGood: "input.passwordStrengthVeryGood",
    },
    login: {
        backActionLabel: "login.backActionLabel",
        title: "login.title",
        formTitle: "login.formTitle",
        formImpersonateTitle: "login.formImpersonateTitle",
        formEmailInputLabel: "login.formEmailInputLabel",
        formEmailValidationMsg: "login.formEmailValidationMsg",
        formPasswordInputLabel: "login.formPasswordInputLabel",
        formButtonCaption: "login.formButtonCaption",
        forgotPasswordLink: "login.forgotPasswordLink",
        loginFailed: "login.loginFailed",
    },
    forgotPassword: {
        title: "forgotPassword.title",
        description: "forgotPassword.description",
        formEmailInputLabel: "forgotPassword.formEmailInputLabel",
        formEmailValidationMsg: "forgotPassword.formEmailValidationMsg",
        formButtonCaption: "forgotPassword.formButtonCaption",
        backButtonCaption: "forgotPassword.backButtonCaption",
        formSubmittedTitle: "forgotPassword.formSubmittedTitle",
        formSubmittedDescription: "forgotPassword.formSubmittedDescription",
        formSubmittedBackButtonCaption: "forgotPassword.formSubmittedBackButtonCaption",
    },
    resetPassword: {
        title: "resetPassword.title",
        formPasswordInputLabel: "resetPassword.formPasswordInputLabel",
        formPasswordValidationMsg: "resetPassword.formPasswordValidationMsg",
        formConfirmPasswordInputLabel: "resetPassword.formConfirmPasswordInputLabel",
        formConfirmPasswordValidationMsg: "resetPassword.formConfirmPasswordValidationMsg",
        formButtonCaption: "resetPassword.formButtonCaption",
        formSubmittedTitle: "resetPassword.formSubmittedTitle",
        formSubmittedDescription: "resetPassword.formSubmittedDescription",
        formSubmittedBackButtonCaption: "resetPassword.formSubmittedBackButtonCaption",
    },
    confirmAccount: {
        title: "confirmAccount.title",
        subTitle: "confirmAccount.subTitle",
        formDisplayUsernameInputLabel: "confirmAccount.formDisplayUsernameInputLabel",
        formPasswordInputLabel: "confirmAccount.formPasswordInputLabel",
        formPasswordValidationMsg: "confirmAccount.formPasswordValidationMsg",
        formConfirmPasswordInputLabel: "confirmAccount.formConfirmPasswordInputLabel",
        formConfirmPasswordValidationMsg: "confirmAccount.formConfirmPasswordValidationMsg",
        formButtonCaption: "confirmAccount.formButtonCaption",
        formSubmittedTitle: "confirmAccount.formSubmittedTitle",
        formSubmittedDescription: "confirmAccount.formSubmittedDescription",
        formSubmittedBackButtonCaption: "confirmAccount.formSubmittedBackButtonCaption",
        contactServiceDesk: "confirmAccount.contactServiceDesk",
    },
    multiselect: {
        selected: "multiselect.selected",
        options: "multiselect.options",
        selectAll: "multiselect.selectAll",
        clearAll: "multiselect.clearAll",
    },

    versionCheck: {
        text: "versionCheck.text",
        button: "versionCheck.button",
    },
    activityTracker: {
        somethingWentWrong: "activityTracker.somethingWentWrong",
        loggedOutInactivtiy: "activityTracker.loggedOutInactivtiy",
    },
    reports: {
        [ReportType.Agreements]: {
            title: "report.agreements.title",
        },
        [ReportType.Payments]: {
            title: "report.payments.title",
        },
        [ReportType.Settlements]: {
            title: "report.settlements.title",
        },
        [ReportType.Transactions]: {
            title: "report.transactions.title",
        },
        [ReportType.Employees]: {
            title: "report.employees.title",
        },
        [ReportType.Dashboard]: {
            title: "report.dashboard.title",
        },
        [ReportType.NotFound]: {
            title: "report.notFound.title",
        },
        [ReportType.MerchantAgreements]: {
            title: "report.merchantAgreements.title",
        },
        [ReportType.MerchantSettlements]: {
            title: "report.merchantSettlements.title",
        },
        [ReportType.CommissionDefinitions]: {
            title: "report.commissionDefinition.title",
            validFrom: "report.commissionDefinition.validFrom",
            validTo: "report.commissionDefinition.validTo",
            percentage: "report.commissionDefinition.percentage",
            transactionFee: "report.commissionDefinition.transactionFee",
            currencyId: "report.commissionDefinition.currencyId",
            feeTransactionCategoryType: "report.commissionDefinition.feeTransactionCategoryType",
            feeBrandType: "report.commissionDefinition.feeBrandType",
            feeCardUsageType: "report.commissionDefinition.feeCardUsageType",
            feeCardCategoryType: "report.commissionDefinition.feeCardCategoryType",
            feeTransactionRegionType: "report.commissionDefinition.feeTransactionRegionType",
            all: "report.commissionDefinition.all",
            updatePending: "report.commissionDefinition.updatePending",
            currentDefinitionFilter: "report.commissionDefinition.currentDefinitionFilter",
            pendingDefinitionFilter: "report.commissionDefinition.pendingDefinitionFilter",
        },
        [ReportType.LinkedMerchantAgreements]: {
            title: "report.linkedMerchantAgreements.title",
            contractNumber: "report.linkedMerchantAgreements.contractNumber",
            contractStatus: "report.linkedMerchantAgreements.contractStatus",
            isCardPresent: "report.linkedMerchantAgreements.isCardPresent",
            currencyId: "report.linkedMerchantAgreements.currencyId",
            createdDate: "report.linkedMerchantAgreements.createdDate",
            dbaNames: "report.linkedMerchantAgreements.dbaNames",
            solution: "report.linkedMerchantAgreements.solution",
            feeCalculationTypes: "report.linkedMerchantAgreements.feeCalculationTypes",
            description: "report.linkedMerchantAgreements.description",
            mccCodes: "report.linkedMerchantAgreements.mccCodes",
            linkedTime: "report.linkedMerchantAgreements.linkedTime",
            lastCreatedSettlementDate: "report.linkedMerchantAgreements.lastCreatedSettlementDate",
            searchString: "report.linkedMerchantAgreements.searchString",
        },
        [ReportType.None]: {
            title: "report.none.title",
        },
        [ReportType.SettlementDefinitions]: {
            title: "report.settlementDefinitions.title",
            bankAccountNumber: "pages.agreementDetails.settlementDefinitions.bankAccountNumber",
            validFrom: "pages.agreementDetails.settlementDefinitions.validFrom",
            validTo: "pages.agreementDetails.settlementDefinitions.validTo",
            settlementCycleStartsOn: "pages.agreementDetails.settlementDefinitions.settlementCycleStartsOn",
            dayOfMonth: "pages.agreementDetails.settlementDefinitions.dayOfMonth",
            iban: "pages.agreementDetails.settlementDefinitions.iban",
            externalId: "pages.agreementDetails.settlementDefinitions.externalId",
            currentDefinitionFilter: "pages.agreementDetails.settlementDefinitions.currentDefinitionFilter",
            pendingDefinitionFilter: "pages.agreementDetails.settlementDefinitions.pendingDefinitionFilter",
            updatePending: "pages.agreementDetails.settlementDefinitions.updatePending",
        },
    },
    networkStatusIndicator: {
        online: "networkStatusIndicator.online",
        offline: "networkStatusIndicator.offline",
    },
};
