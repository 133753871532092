import classNames from "classnames";
import { ToolTipIcon } from "components/icons";
import React from "react";
import "./baseToolTip.scss";

interface IToolTipProps {
    data: string[];
    position?: ToolTipDataPositon;
}

// postion of the icon in relation to data
enum ToolTipDataPositon {
    TopLeft,
    TopRight,
    BottomLeft,
    BottomRight,
}

const BaseToolTip = ({ data, position = ToolTipDataPositon.TopLeft }: IToolTipProps) => {
    return (
        <div
            className={classNames("tool-tip", position, {
                "tool-tip--top-left": position == ToolTipDataPositon.TopLeft,
                "tool-tip--top-right": position == ToolTipDataPositon.TopRight,
                "tool-tip--bottom-left": position == ToolTipDataPositon.BottomLeft,
                "tool-tip--bottom-right": position == ToolTipDataPositon.BottomRight,
            })}
        >
            <div className="tool-tip__icon">
                <ToolTipIcon />
            </div>
            <div className="tool-tip__data">
                {data.map((element, index) => {
                    return (
                        <React.Fragment key={index}>
                            {element} <br />
                        </React.Fragment>
                    );
                })}
            </div>
        </div>
    );
};

export default BaseToolTip;
