import { createAsyncThunk } from "@reduxjs/toolkit";
import { IChangeDefaultLanguageRequest, IChangePasswordRequest } from "redux/models/userModels";
import { executeAxiosRequestWithRefresh } from "redux/services";
import { IPageSettingsRequest } from "utils/models";

const baseUsersRoute = "/api/users";

export const userGetSettings = createAsyncThunk("user/getSettings", async () => {
    return await executeAxiosRequestWithRefresh({
        url: `${baseUsersRoute}/settings`,
        method: "GET",
    });
});

export const userChangeDefaultLanguage = createAsyncThunk<any, IChangeDefaultLanguageRequest>(
    "user/changeDefaultLanguage",
    async request => {
        await executeAxiosRequestWithRefresh({
            url: `${baseUsersRoute}/change-default-language`,
            method: "POST",
            data: {
                languageType: request.languageType,
            },
        });

        // Response just returns OK, so we send request language data to update the store.
        return request;
    }
);

export const userChangePassword = createAsyncThunk<any, IChangePasswordRequest>(
    "user/changePassword",
    async request => {
        const response = await executeAxiosRequestWithRefresh({
            url: `${baseUsersRoute}/change-password`,
            method: "POST",
            data: {
                existingPassword: request.existingPassword,
                newPassword: request.newPassword,
            },
        });

        return response.data;
    }
);

export const updateUserSettings = createAsyncThunk<any, IPageSettingsRequest>(
    "user/update-user-column-selection",
    async request => {
        const data = localStorage.getItem(`${request.pageType}/${request.reportType}`);

        const response = await executeAxiosRequestWithRefresh({
            url: `${baseUsersRoute}/update-user-column-selection`,
            method: "POST",
            data: {
                pageTypeKey: `${request.pageType}/${request.reportType}`,
                userSettings: data ?? JSON.stringify({ columns: request.columns }),
            },
        });

        return request;
    }
);
